import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function SafeFairPlayPolicy() {
    return (
        <Layout>
            <Seo title="Safe and Fair Play Policy" />
            <section className="hero bg_img" data-background="/bg/hero.jpg">
                <div className="hero__shape">
                    <img src="/elements/hero/shape-2.png" alt="no surrender" />
                </div>
                <div className="el-1">
                    <img src="/elements/hero/el-1.png" alt="no surrender" />
                </div>
                <div className="el-2">
                    <img src="/elements/hero/el-2.png" alt="no surrender" />
                </div>
                <div className="el-3">
                    <img src="/elements/hero/el-3.png" alt="no surrender" />
                </div>
                <div className="el-4">
                    <img src="/elements/hero/el-4.png" alt="no surrender" />
                </div>
                <div className="el-5">
                    <img src="/elements/hero/el-5.png" alt="no surrender" />
                </div>
                <div className="el-6">
                    <img src="/elements/hero/el-6.png" alt="no surrender" />
                </div>
                <div className="el-7">
                    <img src="/elements/hero/el-7.png" alt="no surrender" />
                </div>
                <div className="el-8">
                    <img src="/elements/hero/el-8.png" alt="no surrender" />
                </div>
                <div className="el-9">
                    <img src="/elements/hero/el-9.png" alt="no surrender" />
                </div>
                <div className="el-10">
                    <img src="/elements/hero/el-10.png" alt="no surrender" />
                </div>
                <div className="el-11">
                    <img src="/elements/hero/el-11.png" alt="no surrender" />
                </div>
            </section>
            <section className="pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="hero__content d-flex flex-column align-items-center">
                                <h2
                                    className="hero__title wow fadeInUp text-center"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                                >
                                    Safe and Fair Play Policy
                                </h2>
                                <div>
                                    <p class="c3"><span class="c0">Thanks for your interest in safe and fair play!</span></p>
                                    <p class="c3"><span class="c0">We consider providing a fair and secure gameplay environment a top priority. In
                                        pursuing that goal, we do not tolerate cheating or abusive behavior. We expect our players to play fairly
                                        and by the rules of the game at all times.</span></p>
                                    <p class="c3"><span class="c19">Misconduct with any of the practices listed below or our </span><span class="c19"><a
                                        class="c1"
                                        href="https://nosurrender.studio/terms-of-service">Terms
                                        of Service</a></span><span class="c0">, will lead to penalties such as, including but not limited to,
                                            revoked in-game currency, temporary game suspension and permanent game account closure.</span></p>
                                    <p class="c3"><span class="c0">Help us create a great gaming environment, and safeguard your account and device by
                                        keeping the following in mind while playing:</span></p>
                                    <h3 class="c4" id="h.8p1d1t6z3kje"><span class="c14">CLASH WITH RESPECT!</span></h3>
                                    <h4 class="c15 c16" id="h.sls7vtt8q881"><span class="c10">DISRUPTIVE BEHAVIOUR</span></h4>
                                    <p class="c3"><span class="c0">We&#39;re all here to enjoy games together. Sometimes that can mean arguing with
                                        friends over lost battles or derbies. That&#39;s fine, but being abusive towards others drains the fun from
                                        the game.</span></p>
                                    <p class="c3"><span class="c0">When engaging in any kind of in-game communication, we kindly ask that you are
                                        respectful of others. This also applies to the language used in your team&#39;s name and description
                                        (whether it&#39;s a Club, Clan, Task Force or Neighbourhood).</span></p>
                                    <p class="c3"><span class="c0">Here are a few examples of that we consider inappropriate:</span></p>
                                    <ol class="c11 lst-kix_1ar007b15ezd-0 start" start="1">
                                        <li class="c12 li-bullet-0"><span class="c0">Hate speech, racism and other discriminatory language</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Obscene or sexually explicit banter</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Threats or harassment</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Excessive swearing</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Bullying</span></li>
                                    </ol>
                                    <p class="c3"><span class="c34 c19">Report!</span><span class="c0">&nbsp;If you encounter another player misbehaving
                                        towards yourself or others, please let us know by using the report button inside the chat. Reports are
                                        reviewed by our trained moderators who will take appropriate action.</span></p>
                                    <p class="c3 c27"><span class="c0"></span></p>
                                    <p class="c3"><span class="c0">Consequences of misconduct: Disruptive behavior can lead to temporary and even
                                        permanent game account closure. Making false reports just to get someone banned can lead to the same.</span>
                                    </p>
                                    <h3 class="c4" id="h.kaf9qmokbfgd"><span class="c14">DON&#39;T BE A CHEAT!</span></h3>
                                    <h4 class="c15 c16" id="h.2mlzq2tsmfdt"><span class="c10">USE OF THIRD PARTY SOFTWARE</span></h4>
                                    <p class="c3"><span class="c0">Third party software consists of unapproved apps that manipulate gameplay. By
                                        altering game functionality, third party software aims to provide unfair advantages while putting your
                                        account and privacy at risk.</span></p>
                                    <p class="c3"><span class="c0">Third party software includes:</span></p>
                                    <ol class="c11 lst-kix_77jpzyjmdnkg-0 start" start="1">
                                        <li class="c12 li-bullet-0"><span class="c0">Hacks, &quot;mods&quot;, or programs that unfairly alter game
                                            functionality</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">&quot;Bots&quot;, or gameplay automation services or scripts</span>
                                        </li>
                                        <li class="c12 li-bullet-0"><span class="c0">Any other programs that aim to modify or provide unearned
                                            progress</span></li>
                                    </ol>
                                    <p class="c3"><span class="c0">Consequences of misconduct: Trying to gain an unfair advantage by using prohibited
                                        3rd party software will result in a permanent ban for any offending account(s).</span></p>
                                    <h3 class="c4" id="h.i58o37iqjzo2"><span class="c14">LEAVE STEALING TO THE GOBLINS!</span></h3>
                                    <h4 class="c15 c16" id="h.vwllmz3krwbw"><span class="c10">UNAUTHORIZED GEM BUYING/SELLING</span></h4>
                                    <p class="c3"><span class="c0">Certain websites and individuals might offer cheaper gems/diamonds. Don&#39;t be
                                        fooled - it&#39;s a scam.</span></p>
                                    <p class="c3"><span class="c0">Such services request private login data (such as your NoSurrender, ID, Apple ID,
                                        Google Play credentials, etc) in order to access your game account. These vendors will gain access to your
                                        account and oftentimes, hijack the account and try selling it to other players.</span></p>
                                    <p class="c3"><span class="c0">IMPORTANT: If you release your private information/credentials to 3rd parties,
                                        you&#39;re permanently placing your game and financial/online security in a high-risk situation.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">Consequences of misconduct: Purchasing gems or diamonds from 3rd party vendors can
                                        lead to revoked in-app currency and can even get your account permanently banned.</span></p>
                                    <h3 class="c4" id="h.evatvgau2aed"><span class="c14">THERE CAN ONLY BE ONE!</span></h3>
                                    <h4 class="c15 c16" id="h.hc441eftdba1"><span class="c10">BUYING, SELLING AND SHARING GAME ACCOUNTS</span></h4>
                                    <p class="c3"><span class="c19">Selling, buying, sharing or giving game accounts to other players is against our
                                    </span><span class="c19"><a class="c1"
                                        href="https://nosurrender.studio/terms-of-service">Terms
                                        of Service</a></span><span class="c0">&nbsp;and never endorsed by NoSurrender.</span></p>
                                    <p class="c3 c27"><span class="c0"></span></p>
                                    <p class="c3"><span class="c0">Account sales involve advertising advanced game accounts, and luring players eager to
                                        progress with tantalizing and unrealistic promises.</span></p>
                                    <p class="c3"><span class="c0">The risks surrounding account sales are:</span></p>
                                    <ol class="c11 lst-kix_9mlq6ob8ln94-0 start" start="1">
                                        <li class="c12 li-bullet-0"><span class="c0">The seller may take your money and never give you the
                                            account</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">You cannot be sure that the seller won&#39;t continue using the
                                            account</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">They may be selling the same account to multiple people</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">The account status is unknown; it could already be poised for
                                            permanent ban</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">The account will never be secure; NoSurrender cannot provide
                                            support for compromised accounts</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">If you choose to spend money on the account, it is still in danger
                                            of permanent closure due to breach of our ToS</span></li>
                                    </ol>
                                    <p class="c3"><span class="c0">Help stop this nasty practice by refusing to sell your account, and reporting those
                                        who do!</span></p>
                                    <p class="c3"><span class="c0">Note: Also sharing an account between multiple players in order to gain competitive
                                        advantages is against our ToS.</span></p>
                                    <p class="c3"><span class="c0">Consequences of misconduct: We cannot guarantee the security of any account that has
                                        been passed from one player to another; we reserve the right to permanently ban any account that has been
                                        transferred between players.</span></p>
                                    <h3 class="c4" id="h.egdqr7zi2i25"><span class="c14">OTHER UNACCEPTABLE BEHAVIOUR</span></h3>
                                    <ol class="c11 lst-kix_3kp8h2uglsdt-0 start" start="1">
                                        <li class="c12 li-bullet-0"><span class="c0">NoSurrender Staff impersonation</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Phishing other players&#39; accounts</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Refund abuse</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Knowingly exploiting a bug</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Messing with Matchmaking</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Match fixing</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Encouraging others to break the rules</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Asking for or providing personal contact information</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Misuse of in-game chat for Advertising, Spamming &amp;
                                            Scamming</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Political statements of affiliation, support, or opposition</span>
                                        </li>
                                    </ol>
                                    <p class="c3"><span class="c0">The rules of our Safe and Fair Play policy (Code of Conduct) apply across all
                                        NoSurrender games &ldquo;I didn&rsquo;t know&hellip;&rdquo; is not a valid excuse. We&rsquo;re all here to
                                        have fun and enjoy the game!</span></p>
                                    <h3 class="c4" id="h.7lij3hxtcq0l"><span class="c14">HOW WE ENFORCE FAIR PLAY</span></h3>
                                    <p class="c3"><span class="c0">Whenever we can, we aim to prevent cheating and disruptive behaviour in our games
                                        e.g. we implemented systems that proactively prevent players from using inappropriate language and/or 3rd
                                        party software. Where we cannot implement preventative methods, we use a mix of automated and manual review
                                        systems. To achieve a fair playing field for every player, we aim to enforce all rules equally to everyone.
                                        While much of our systems are automated, we also employ trained moderators who focus on leaderboards,
                                        tournament participants and community sourced reports. If you feel like you have received an unjustified
                                        penalty, please contact support and we will review your case.</span></p>
                                    <p class="c3 c27"><span class="c0"></span></p>
                                    <p class="c15 c27 c32"><span class="c20"></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default SafeFairPlayPolicy
